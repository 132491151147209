<template>
  <div>
    <router-view />
    <FooterComp></FooterComp>
  </div>
</template>

<script>
import FooterComp from "@/components/FooterComp.vue";

export default {
  data() {
    return {
      currentLanguage: "ar",
    };
  },
  methods: {
    addClass() {
      var body = document.body;
      // console.log("sss", this.currentLanguage);
      if (this.currentLanguage == "ar") {
        body.classList.remove("en");
        body.classList.toggle("ar");
      } else {
        body.classList.remove("ar");
        body.classList.toggle("en");
      }
    },
  },
  components: {
    FooterComp,
  },
  mounted() {
    this.addClass();
  },
};
</script>

<style lang="scss">
#app {
  @font-face {
    font-family: "Marhey";
    src: url("@/assets/fonts/Marhey-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Tajawal";
    src: url("@/assets/fonts/Tajawal-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  font-family: Tajawal, Marhey, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body.ar {
  // direction: rtl;
  text-align: right;
  .mobile-menu.is-open {
    right: 0;
  }
}
//body.ar #offcanvasExample {
//  right: 0;
//}
.brand {
  direction: ltr;
}
.nav-left {
  direction: ltr;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
}

body,
html {
  touch-action: auto;
}
</style>
